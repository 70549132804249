// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState, useEffect } from "react";
import { Button, Flex, Grid, Select, Stack, Title } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useForm, UseFormReturnType } from "@mantine/form";
import {
	InspectionIssueAdd,
	IssueAdd,
	postIssue,
} from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import {
	AddIssueFormValues,
	AddIssueProps,
	InspectionAddIssueValue,
} from "./utils/add-issue-types";
import classes from "./AddIssue.module.scss";
import equipmentOptions from "../../../utilities/client/station-equipment";
import problemOptions from "./utils/problem-options";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import {
	AddIssueTransformValues,
	transformValuesToAddIssue,
	transformValuesToInspectionIssueAdd,
} from "./utils/transform-values";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import initialValues from "./utils/initial-values";
import validate from "./utils/validate";
import DashboardDateTimePicker from "../../DashboardDateTimePicker";
import DashboardTextarea from "../../DashboardTextarea/DashboardTextarea";
import {
	showSubmitErrorNotification,
	showSubmitSuccessfulNotification,
} from "./utils/notifications";
import DashboardSelectStation from "../../DashboardSelectStation/DashboardSelectStation";

function AddIssue({ values, onSubmit }: AddIssueProps): ReactElement {
	const isMobile = useIsMobileView();
	const navigate = useNavigate();

	const [userRole] = useGetUserRoles();
	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

	const form: UseFormReturnType<
		AddIssueFormValues,
		typeof values extends InspectionAddIssueValue
			? AddIssueTransformValues<InspectionIssueAdd>
			: AddIssueTransformValues<IssueAdd>
	> = useForm({
		mode: "uncontrolled",
		validateInputOnChange: true,
		initialValues: initialValues(userRole, values),
		validate,
		transformValues:
			values && "photoIds" in values
				? transformValuesToInspectionIssueAdd
				: transformValuesToAddIssue,
	});

	// for better type support
	const formKey = form.key<keyof AddIssueFormValues>;
	const formGetInputProps = form.getInputProps<keyof AddIssueFormValues>;

	useEffect(() => {
		if (form.isValid() && form.isDirty()) {
			setIsSaveButtonEnabled(true);
		} else {
			setIsSaveButtonEnabled(false);
		}
	}, [form]);

	useEffect(() => {
		form.setValues({ createdBy: userRole });
	}, [userRole]);

	return (
		<Stack className={classes.container}>
			<Title order={3}>Neue Problemmeldung</Title>
			<form
				onSubmit={form.onSubmit((submitValues) =>
					values
						? onSubmit?.(submitValues)
						: postIssue(submitValues)
								.then(() => {
									showSubmitSuccessfulNotification();
									navigate(-1);
								})
								.catch(showSubmitErrorNotification)
				)}
			>
				<Grid gutter={24}>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<DashboardSelectStation
							key={formKey("stationId")}
							formInputProps={formGetInputProps("stationId")}
							withAsterisk
							disabled={values !== undefined}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<DashboardDateTimePicker
							key={formKey("createdAt")}
							formInputProps={formGetInputProps("createdAt")}
							label="Datum"
							withAsterisk
							disabled={values !== undefined}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Select
							key={formKey("equipment")}
							{...formGetInputProps("equipment")}
							label="Ausstattung"
							withAsterisk
							placeholder="Bitte Ausstattung auswählen"
							data={equipmentOptions}
							searchable
							disabled={values !== undefined}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Select
							key={formKey("problem")}
							{...formGetInputProps("problem")}
							label="Problem"
							withAsterisk
							placeholder="Bitte Problem auswählen"
							searchable
							data={problemOptions}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12 }}>
						<DashboardTextarea
							key={formKey("description")}
							formInputProps={formGetInputProps("description")}
							label="Beschreibungstext"
							withAsterisk
							placeholder="Beschreibe das Problem"
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12 }}>
						<PhotoUpload
							key={formKey("photos")}
							{...formGetInputProps("photos")}
							maxItems={5}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Flex
							gap="md"
							justify="flex-end"
							align="flex-end"
							direction={isMobile ? "column-reverse" : "row"}
						>
							<Link
								to=".."
								relative="path"
								style={isMobile ? { width: "100%" } : {}}
							>
								<Button variant="default" fullWidth={isMobile}>
									Abbrechen
								</Button>
							</Link>
							<Button
								type="submit"
								fullWidth={isMobile}
								disabled={!isSaveButtonEnabled}
							>
								{onSubmit ? "Weiter" : "Speichern"}
							</Button>
						</Flex>
					</Grid.Col>
				</Grid>
			</form>
		</Stack>
	);
}

export default AddIssue;
