import { UserRole } from "../../../../utilities/api/jelbi-dashboard-api";
import { AddIssueFormValues, AddIssueProps } from "./add-issue-types";

export type AddIssueInitialValues = (
	userRole: UserRole,
	values?: AddIssueProps["values"]
) => AddIssueFormValues;

const initialValues: AddIssueInitialValues = (userRole, values) => ({
	createdAt: values?.createdAt ? new Date(values.createdAt) : new Date(),
	createdBy: userRole,
	description: values?.description ?? "",
	equipment: values?.equipment ?? "",
	photos: values?.photoIds
		? values.photoIds.map((id) => ({
				isProcessing: true,
				id,
			}))
		: [],
	problem: values?.problem ?? "",
	stationId: values?.stationId ?? "",
});

export default initialValues;
