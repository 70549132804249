import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { ComboboxData } from "@mantine/core";
import stationService from "../../services/stationService";
import { QUERY_KEY_ALL_STATIONS } from "../../utilities/client/query-keys";
import { StationState } from "../../utilities/api/jelbi-dashboard-api";

const useStationForSelectData = (): ComboboxData => {
	const [stationsForSelectData, setStationsForSelectData] =
		useState<ComboboxData>([]);

	const { data: stations } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: stationService.fetchStations,
	});

	useEffect(() => {
		if (stations && stations.length > 0) {
			setStationsForSelectData(
				stations
					.filter((station) => station.state !== StationState.Inactive)
					.map((station) => ({
						value: station.id,
						label: `${station.shortName} ${station.name}`,
					}))
			);
		}
	}, [stations]);

	return stationsForSelectData;
};

export default useStationForSelectData;
