import React, { ReactElement } from "react";
import { useQuery } from "@tanstack/react-query";
import {
	Station,
	VehicleType,
	VehiclesStatistic,
} from "../../../../utilities/api/jelbi-dashboard-api";
import {
	getVehicleOrder,
	isMicromobility,
} from "../../../../utilities/client/vehicles";
import distributionService from "../../../../services/distributionService";
import { ONE_MINUTE } from "../../../../utilities/client/refetch-intervals";
import VehicleAccumulatedList from "../VehicleAccumulatedList";
import VehicleAccumulatedTable from "../VehicleAccumulatedTable";
import styles from "./index.module.scss";
import { getVehicleAvailabilityStationLiveWeekQueryKey } from "../../../../utilities/client/query-keys";
import useIsMobileView from "../../../../utilities/client/hooks/useIsMobileView";

type VehicleAccumulatedProps = {
	station: Station;
	accumulateOverWeek: boolean;
	showMsps: boolean;
};

function VehicleAccumulated({
	station,
	accumulateOverWeek,
	showMsps,
}: VehicleAccumulatedProps): ReactElement {
	const getLiveAvailabilitiesResponse = useQuery({
		queryKey: getVehicleAvailabilityStationLiveWeekQueryKey(
			accumulateOverWeek ? "WEEK" : "LIVE",
			station.id,
			showMsps
		),
		queryFn: () =>
			distributionService.fetchAvailabilitiesStationLiveWeek(
				station.id,
				showMsps,
				accumulateOverWeek
			),
		refetchInterval: ONE_MINUTE,
	});
	const isViewMobile = useIsMobileView();

	const defaultAvailabilitiesValue: VehiclesStatistic = {
		start: "",
		end: "",
		vehicleDistributions: [],
	};
	const availabilities: VehiclesStatistic =
		getLiveAvailabilitiesResponse.isSuccess
			? getLiveAvailabilitiesResponse.data.results[0]
			: defaultAvailabilitiesValue;

	const availableVehicleTypes = station.vehicles
		.filter(({ vehicleType }) => vehicleType !== VehicleType.CargoBike)
		.sort((a, b) => {
			return getVehicleOrder(a.vehicleType) - getVehicleOrder(b.vehicleType);
		})
		.filter(
			({ vehicleType, capacity }) =>
				isMicromobility(vehicleType) || capacity !== 0
		)
		.map(({ vehicleType }) => vehicleType);

	return (
		<div className={styles["vehicle-accumulated__listing"]}>
			{isViewMobile ? (
				<VehicleAccumulatedList
					availabilities={availabilities}
					showMsps={showMsps}
					availableVehicleTypes={availableVehicleTypes}
					showLoading={!getLiveAvailabilitiesResponse.isSuccess}
				/>
			) : (
				<VehicleAccumulatedTable
					availabilities={availabilities}
					showMsps={showMsps}
					availableVehicleTypes={availableVehicleTypes}
					showLoading={!getLiveAvailabilitiesResponse.isSuccess}
				/>
			)}
		</div>
	);
}

export default VehicleAccumulated;
